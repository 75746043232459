import firebase from "firebase/app";
const firebaseAppModule = async (module) => {
  if (module === "database") {
    await import("firebase/database");
  }
  if (module === "auth") {
    await import("firebase/auth");
  }
  if (module === "firestore") {
    await import("firebase/firestore");
  }

  const firebaseConfig = {
    apiKey: "AIzaSyDU7pnAHmVuuf2nKxa5HpBBI4GaCobCQRw",
    authDomain: "momblog-15d1c.firebaseapp.com",
    databaseURL: "https://momblog-15d1c.firebaseio.com",
    projectId: "momblog-15d1c",
    storageBucket: "",
    messagingSenderId: "754776938435",
    appId: "1:754776938435:web:43cadca033fb5094ec0f76",
  };
  // INITIALIZE FIREBASE IF NOT YET INITIALIZED
  // SINGLETON

  const app = !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();
  // return actully usable objects:
  if (module === "database") {
    return {
      database: app.database(),
    };
  }
  if (module === "auth") {
    return {
      auth: app.auth(),
    };
  }
  if (module === "firestore") {
    return {
      firestore: app.firestore(),
    };
  }
};
export default firebaseAppModule;
